<template>
  <KTCard :page="page">
    <template #body>
      <KTTable
        :fields="receita_columns"
        :items="receita.data"
        :primaryKey="receita.primaryKey"
        :sortBy="receita.sortBy"
      >
        <template #cell(_actions)="{ item }">
          <KTListMiniButton :to="getEditRoute(item.idpip_produto)" />
        </template>

        <template #custom-foot>
          <tr class="bg-primary text-white" v-if="receita.foot_data[0]">
            <td></td>
            <td></td>
            <td>Total</td>
            <td></td>
            <td class="text-center">{{ simbolo_moeda }}</td>
            <template v-for="(ano, index) in anos">
              <td :key="'td-anos-2-' + index"></td>
              <td :key="'td-anos-3-' + index" class="text-right">
                {{
                  formatCurrency(
                    receita.foot_data[0]["valor_vendas_total_ano_" + ano]
                  )
                }}
              </td>
            </template>
          </tr>
        </template>
      </KTTable>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipReceitaList",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-thumbs-up",
        title: "Receitas",
        description: `A receita é calculada a partir da quantidade
          projetada de venda de cada produto e seu respectivo preço
          de venda. Você pode verificar maiores detalhes através do
          botão Listar localizado abaixo de AÇÕES.`
      },
      receita: {
        data: [],
        foot_data: [],
        primaryKey: "idpip_produto",
        sortBy: "numero_item"
      },
      simbolo_moeda: "",
      anos: 0,
      tipo_plano_investimento: null
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/receita`;
    },
    manual() {
      return {
        session: "receita",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_ver",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    receita_columns() {
      let columns = [
        {
          key: "_actions",
          label: "Ações",
          sortable: false,
          tdClass: "text-center"
        },
        {
          key: "numero_item",
          label: "Item",
          sortable: true
        },
        {
          key: "descricao",
          label: "Descrição",
          sortable: true
        },
        {
          key: "nome_unidade_medida",
          label: "Unidade de Medida",
          sortable: true,
          tdClass: "text-center"
        },
        {
          key: "simbolo_moeda",
          label: "Moeda",
          sortable: true,
          tdClass: "text-center"
        }
      ];

      for (let ano = 1; ano <= this.anos; ano++) {
        columns.push({
          key: "quantidade_vendas_total_ano_" + ano,
          label: "Quantidade Ano " + ano,
          sortable: true,
          tdClass: "text-right",
          formatter: "number"
        });
        columns.push({
          key: "valor_vendas_total_ano_" + ano,
          label: "Valor Total Ano " + ano,
          sortable: true,
          tdClass: "text-right",
          formatter: "currency"
        });
      }

      return columns;
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetList()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETLIST = 0;

        this.receita.data = res[R_GETLIST].receita;
        this.receita.foot_data = res[R_GETLIST].receita_total;
        this.simbolo_moeda = res[R_GETLIST].simbolo_moeda;
        this.anos = res[R_GETLIST].anos;
        this.tipo_plano_investimento = res[R_GETLIST].tipo_plano_investimento;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    getEditRoute(id) {
      return {
        name: "pip_receita_edit",
        params: {
          idplano_investimento: this.idplano_investimento,
          idpip_produto: id
        }
      };
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
